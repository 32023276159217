<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Manage Special Price pdf</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <app-data-table
      [tableHeaders]="MainHeaders"
      [tableEntries]="MainEntries"
      (save)="openFile($event)"
      [pagination]="false"
    ></app-data-table>
    <div class="mt-8 flex gap-x-8">
      <div class="flex w-1/4 max-w-72 flex-col gap-4">
        <h2 class="sub-heading">Select Files</h2>
        <input
          type="file"
          name=""
          id=""
          (change)="onFileChange($event)"
          accept=".gif, .png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .txt"
          multiple
        />
        <div class="reminder-div">
          <h3 class="mb-4 text-lg">Reminder!</h3>
          <p class="text-sm">
            Kindly upload in the following format: gif, png, jpg,
            jpeg, pdf, doc, docx, xls, xlsx, txt.
          </p>
        </div>
      </div>
      <div class="flex flex-1 flex-col gap-4">
        <h2 class="sub-heading">Upload queue</h2>
        <p>Queue length: {{ SecondaryEntries.length }}</p>
        <table class="w-full text-sm">
          <thead>
            <tr>
              <td
                *ngFor="
                  let header of SecondaryHeaders;
                  let index = index
                "
                [ngClass]="{ 'w-5/12': index == 0 }"
                class="bg-primary p-2 font-bold text-white"
              >
                {{ header }}
              </td>
            </tr>
          </thead>
          <tbody class="secondary-table text-sm">
            <tr *ngFor="let entry of SecondaryEntries">
              <td class="font-bold">
                {{ entry.Name }}
              </td>
              <td>
                {{ entry.Size }}
              </td>
              <td>
                <div class="progress-bar-container">
                  <div
                    class="progress-bar"
                    [style.width.%]="entry.Progress * 100"
                  ></div>
                </div>
              </td>
              <td class="text-center">
                <fa-icon
                  *ngIf="entry.Status"
                  [icon]="faCheck"
                ></fa-icon>
              </td>
              <td class="flex min-w-64 gap-1">
                <button
                  class="file-btn shorter-btn add-btn"
                  [disabled]="entry.Status"
                  (click)="uploadEntries(entry.Name)"
                >
                  <fa-icon [icon]="faUpload" class="mr-1"></fa-icon
                  >Upload
                </button>
                <button
                  class="file-btn shorter-btn clear-btn"
                  [disabled]="entry.Status"
                >
                  <fa-icon [icon]="faCancel" class="mr-1"></fa-icon
                  >Cancel
                </button>
                <button
                  class="file-btn shorter-btn delete-btn"
                  (click)="removeEntry(entry.Name)"
                >
                  <fa-icon [icon]="faTrash" class="mr-1"></fa-icon
                  >Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p>Queue progress:</p>
        <div class="progress-bar-container">
          <div
            class="progress-bar"
            [style.width.%]="totalProgress * 100"
          ></div>
        </div>
        <div class="flex gap-1">
          <button
            class="shorter-btn add-btn"
            [disabled]="SecondaryEntries.length == 0"
            (click)="uploadEntries()"
          >
            <fa-icon [icon]="faUpload" class="mr-1"></fa-icon>Upload
            All
          </button>
          <button
            class="shorter-btn clear-btn"
            [disabled]="isCancelDisabled()"
          >
            <fa-icon [icon]="faCancel" class="mr-1"></fa-icon>Cancel
            All
          </button>
          <button
            class="shorter-btn delete-btn"
            [disabled]="SecondaryEntries.length == 0"
            (click)="removeEntry()"
          >
            <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Remove
            All
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
