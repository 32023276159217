import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService, HttpResponse } from "../http-util.service";
import { file } from "src/app/models/sales-reports/document-download";

@Injectable({
  providedIn: "root",
})
export class DocumentDownloadService {
  constructor(private http: HttpUtilService) {}

  GetAll(): Observable<
    HttpResponse<{ Documents: file[]; GroupList: string[] }>
  > {
    return this.http.get("DocumentService.asmx/GetAll");
  }

  DeleteDocument(fileName: string, id: string): Observable<any> {
    return this.http.post("DocumentService.asmx/DeleteDocument", {
      fileName,
      id,
    });
  }

  UploadFiles(
    file: ArrayBuffer,
    fileName: string,
    groupName: string,
  ): Observable<any> {
    const formData = new FormData();

    const blob = new Blob([file], {
      type: "application/octet-stream",
    });
    formData.append("file", blob, fileName);
    formData.append("docType", "Document");
    formData.append("groupName", groupName);

    return this.http.post("UploadFiles.ashx", formData);
  }

  /* GetBasicActiveModelList(): Observable<HttpResponse<active[]>> {
    return this.http.get(
      "ModelSpecificationService.asmx/GetBasicActiveModelList",
    );
  }

  GetDetails(id: string): Observable<HttpResponse<model>> {
    return this.http.get(
      "SpecialPriceListMasterService.asmx/GetDetails",
      {
        params: { id },
      },
    );
  }

  Update(model: model): Observable<HttpResponse<model>> {
    return this.http.post(
      "SpecialPriceListMasterService.asmx/Update",
      {
        model,
      },
    );
  }

  Add(model: any): Observable<HttpResponse<any>> {
    return this.http.post("SpecialPriceListMasterService.asmx/Add", {
      model,
    });
  }

  GetAllFiles(): Observable<HttpResponse<any[]>> {
    return this.http.get(
      "SpecialPriceListMasterService.asmx/GetAllFiles",
    );
  }

  UploadFiles(file: ArrayBuffer, fileName: string): Observable<any> {
    const formData = new FormData();

    const blob = new Blob([file], {
      type: "application/octet-stream",
    });
    formData.append("file", blob, fileName);
    formData.append("docType", "SpecialPriceList");

    return this.http.post("UploadFiles.ashx", formData);
  } */
}
