import { Component, OnInit } from "@angular/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { AllInvoicesService } from "src/app/services/sales-reports/all-invoices.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-all-invoices",
  templateUrl: "./all-invoices.component.html",
  styleUrls: ["./all-invoices.component.css"],
})
export class AllInvoicesComponent implements OnInit {
  faPrint = faPrint;

  formFields: FormField[] = [
    {
      type: "date",
      id: "fromDate",
      label: "From Date (dd/mm/yyyy)",
      value: "",
    },
    {
      type: "date",
      id: "toDate",
      label: "To Date (dd/mm/yyyy)",
      value: "",
    },
    {
      type: "select",
      id: "format",
      label: "Select format",
      value: "BOTH",
      options: [
        { value: "PDF", display: "PDF" },
        { value: "EXCEL", display: "EXCEL" },
        { value: "BOTH", display: "BOTH" },
      ],
    },
  ];

  constructor(
    private allInvoicesService: AllInvoicesService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  printAllInvoices(): void {
    const fromDate = this.getFieldValue("fromDate");
    const toDate = this.getFieldValue("toDate");
    const format = this.getFieldValue("format");

    if (!fromDate || !toDate) {
      this.notifier.notify("error", "Please select both From Date and To Date.");
      return;
    }

    const formattedFromDate = this.formatDate(fromDate);
    const formattedToDate = this.formatDate(toDate);

    const payload = {
      model: {
        FromDate: formattedFromDate,
        ToDate: formattedToDate,
      },
    };

    this.allInvoicesService.getAllInvoices(formattedFromDate, formattedToDate).subscribe({
      next: (response) => {
        this.handleInvoiceReportResponse(response, format);
      },
      error: (error) => {
        console.error("Error generating All Invoices Report:", error);
        this.notifier.notify("error", "Error generating All Invoices Report.");
      },
    });
  }

  private handleInvoiceReportResponse(response: any, format: string): void {
    switch (format) {
      case "PDF":
        if (response.body?.Name) {
          window.open(response.body.Name, "_blank");
        } else {
          this.notifier.notify("error", "PDF file is not available.");
        }
        break;
      case "EXCEL":
        if (response.body?.Value) {
          window.open(response.body.Value, "_blank");
        } else {
          this.notifier.notify("error", "Excel file is not available.");
        }
        break;
      case "BOTH":
        if (response.body?.Name) {
          window.open(response.body.Name, "_blank");
        } else {
          this.notifier.notify("error", "PDF file is not available.");
        }
        if (response.body?.Value) {
          window.open(response.body.Value, "_blank");
        } else {
          this.notifier.notify("error", "Excel file is not available.");
        }
        break;
      default:
        this.notifier.notify("error", "Invalid format selected.");
    }
  }

  private getFieldValue(id: string): any {
    const field = this.formFields.find((f) => f.id === id);
    return field?.value || "";
  }

  private formatDate(date: string): string {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }
}
