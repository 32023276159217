import { Component, OnInit } from "@angular/core";
import {
  faUpload,
  faCancel,
} from "@fortawesome/free-solid-svg-icons";
import { DocumentDownloadService } from "src/app/services/sales-reports/document-download";
import { NotifierService } from "angular-notifier";
import { FormField } from "src/app/interfaces/form-field.model";
import { environment } from "src/environments/environment";
import { file } from "src/app/models/sales-reports/document-download";

@Component({
  selector: "app-document-download",
  templateUrl: "./document-download.component.html",
  styleUrls: ["./document-download.component.css"],
})
export class DocumentDownloadComponent implements OnInit {
  faUpload = faUpload;
  faCancel = faCancel;

  showUpload: boolean = false;
  showDelete: boolean = false;

  tableHeaders = [
    {
      title: "FILE NAME",
      type: "data",
      id: "FileName",
    },
    {
      title: "DOWNLOAD",
      type: "download",
      id: "Download",
      Hclasses: "text-center",
      classes: "max-w-14 mx-auto",
    },
    {
      title: "DELETE",
      type: "trash",
      id: "Delete",
      Hclasses: "text-center",
      classes: "max-w-20 mx-auto",
    },
  ];
  groupList: string[] = [];
  documents: file[] = [];
  toRemoveEntry: any = undefined;
  newFile: any = undefined;

  documentsForm: FormField[] = [
    {
      type: "select",
      id: "groupName",
      label: "Select Group",
      options: [],
    },
    {
      type: "text",
      id: "newGroup",
      label: "Or New Group",
    },
  ];

  constructor(
    private documentDownloadService: DocumentDownloadService,
    private notifier: NotifierService,
  ) {}

  ngOnInit() {
    this.documentDownloadService.GetAll().subscribe({
      next: (response) => {
        this.groupList = response.body.GroupList;
        this.documents = response.body.Documents;
        this.documentsForm[0].options = response.body.GroupList.map(
          (item) => ({ display: item, value: item }),
        );
      },
      error: console.error,
    });
  }

  handleGroup(group: string): any {
    let entries = this.documents
      .filter((item) => item.GroupName == group)
      .map((item) => ({
        FileName: item.FileName,
        Download: "",
        Delete: "",
      }));
    return entries;
  }

  openDelete(state: boolean, event?: any, group?: string): void {
    this.showDelete = state;
    document.body.style.overflow = state ? "hidden" : "auto";
    this.toRemoveEntry = this.documents.find(
      (item) =>
        item.GroupName == group && item.FileName == event.FileName,
    );
  }

  removeEntry(): void {
    this.documentDownloadService
      .DeleteDocument(
        this.toRemoveEntry.FileName,
        this.toRemoveEntry.Id,
      )
      .subscribe({
        next: () => {
          this.showDelete = false;
          document.body.style.overflow = "auto";
          this.ngOnInit();
          this.notifier.notify(
            "success",
            "Document has been deleted successfully",
          );
          this.toRemoveEntry = undefined;
        },
        error: console.error,
      });
  }

  downloadFile(event: any, group: string): void {
    const file = this.documents.find(
      (item) =>
        item.GroupName == group && item.FileName == event.FileName,
    );
    window.open(
      `${environment.KAPS_API_BACKEND_URL}DocumentService.asmx/DownloadDocument?filename=${encodeURIComponent(file!.Path)}&downloadFileName=${encodeURIComponent(file!.FileName)}`,
      "_blank",
    );
  }

  openUpload(state: boolean): void {
    this.showUpload = state;
    document.body.style.overflow = state ? "hidden" : "auto";
  }

  onFileChange(event: any): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      this.newFile = target.files[0];
    }
  }

  uploadFile(): void {
    if (
      !this.newFile ||
      this.documentsForm.every((item) => item.value == null)
    ) {
      this.notifier.notify(
        "error",
        "No file selected or all inputs are empty",
      );
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = () => {
      const arrayBuffer = fileReader.result as ArrayBuffer;
      const fileName = this.newFile.name;
      let groupName = "";
      if (this.documentsForm[1].value) {
        groupName = this.documentsForm[1].value;
      } else {
        groupName = this.documentsForm[0].value;
      }
      console.log(this.documentsForm);

      this.documentDownloadService
        .UploadFiles(arrayBuffer, fileName, groupName)
        .subscribe({
          next: () => {
            this.notifier.notify(
              "success",
              "File uploaded successfully",
            );
            this.ngOnInit();
          },
          error: console.error,
        });
    };

    fileReader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    fileReader.readAsArrayBuffer(this.newFile);
  }
}
