import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService, HttpResponse } from "../http-util.service";
import { file } from "src/app/models/sales-reports/document-download";

@Injectable({
  providedIn: "root",
})
export class OutstandingDeliveryService {
  constructor(private http: HttpUtilService) {}

  OutstandingDeliveryReport(
    id: string,
    FromDate: string,
    ToDate: string,
  ): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/OutstandingDeliveryReport",
      {
        id,
        model: {
          FromDate,
          ToDate,
        },
      },
    );
  }
}
