import { Component } from '@angular/core';
import { LoanStatusService } from 'src/app/services/sales/loan-status.service';
import { FormField } from 'src/app/interfaces/form-field.model';
import { NotifierService } from 'angular-notifier';
import { faSave } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-loan-status',
  templateUrl: './loan-status.component.html',
  styleUrls: ['./loan-status.component.css'],
})

export class LoanStatusComponent {

  // Icons imports
  faSave = faSave;

  formFields: FormField[] = [
    { type: 'search', id: 'buyersOrderNumber', label: "Buyer's Order Number", searchEvent: true, searchType: 'buyersOrder' },
    { type: 'search', id: 'sfdcNumber', label: 'SFDC Number', searchEvent: true, searchType: 'sfdc' },
    { type: 'text', id: 'nricNumber', label: 'NRIC No/RCB No/Passport No' },
    { type: 'text', id: 'nameAsInNRIC', label: 'Name as in NRIC/Passport' },
    { type: 'number', id: 'loanAmount', label: 'Loan Amount ($)' },
    { type: 'number', id: 'loanPeriod', label: 'Loan Period (Months)' },
    { type: 'number', id: 'loanRate', label: 'Loan Rate (%)' },
    { type: 'number', id: 'monthlyInstallment', label: 'Monthly Installment ($)', lock: true },
    { type: 'text', id: 'financeCompany1', label: 'Finance Company 1', lock: true },
    {
      type: 'select',
      id: 'financeCompanyStatus1',
      label: 'Status',
      options: [
        { value: 'Pending', display: 'Pending' },
        { value: 'Approved', display: 'Approved' },
        { value: 'Rejected', display: 'Rejected' },
      ],
      lock: true 
    },
    { type: 'text', id: 'financeCompany2', label: 'Finance Company 2', lock: true },
    {
      type: 'select',
      id: 'financeCompanyStatus2',
      label: 'Status',
      options: [
        { value: 'Pending', display: 'Pending' },
        { value: 'Approved', display: 'Approved' },
        { value: 'Rejected', display: 'Rejected' },
      ],
      lock: true 
    },
    { type: 'text', id: 'financeCompany3', label: 'Finance Company 3', lock: true },
    {
      type: 'select',
      id: 'financeCompanyStatus3',
      label: 'Status',
      options: [
        { value: 'Pending', display: 'Pending' },
        { value: 'Approved', display: 'Approved' },
        { value: 'Rejected', display: 'Rejected' },
      ],
      lock: true 
    },
    { type: 'text', id: 'financeCompany4', label: 'Finance Company 4', lock: true },
    {
      type: 'select',
      id: 'financeCompanyStatus4',
      label: 'Status',
      options: [
        { value: 'Pending', display: 'Pending' },
        { value: 'Approved', display: 'Approved' },
        { value: 'Rejected', display: 'Rejected' },
      ],
      lock: true 
    },
    { type: 'switch', id: 'financeProblem', label: 'Finance Problem' },
    { type: 'date', id: 'loanSubmittedDate', label: 'Loan Submitted Date (dd/mm/yyyy)' },
    { type: 'date', id: 'loanStatusDate', label: 'Loan Status Date (dd/mm/yyyy)' },
    { type: 'text', id: 'remark', label: 'Remark' },
    { type: 'text', id: 'loanStatus', label: 'Loan Status', lock: true },
  ];

  constructor(
    private loanStatusService: LoanStatusService,
    private notifier: NotifierService
  ) {}

  fetchLoanStatus(event: any): void {
    const searchField = this.formFields.find(field => field.id === event.fieldId);
  
    if (!searchField || !searchField.value) {
      this.notifier.notify('error', 'Please enter a valid reference ID.');
      return;
    }
    
    const referenceId = searchField.value.replace(/['"]/g, '');
    
    this.loanStatusService.getLoanStatus(referenceId).subscribe({
      next: (response) => {
        //kconsole.log('Full response for GetLoanStatus:', response); 
    
        let data = null;

        data = response.body;
    
        if (data) {
          this.updateFormFields(data);
        } else {
          this.notifier.notify('warning', 'No loan status details found.');
        }
      },
      error: (error) => {
        console.error('Error fetching loan status:', error);
        this.notifier.notify('error', 'Failed to fetch loan status.');
      },
    });    
  }
  
  private updateFormFields(data: any): void {
    const convertDateToYYYYMMDD = (dateString: string | null): string => {
      if (!dateString) return '';
      const [day, month, year] = dateString.split('/');
      return `${year}-${month}-${day}`;
    };
  
    this.setFieldValue('nricNumber', data.NrIc);              // NRIC No/RCB No/Passport No
    this.setFieldValue('nameAsInNRIC', data.Name);            // Name as in NRIC/Passport
    this.setFieldValue('loanAmount', data.LoanAmount);        // Loan Amount ($)
    this.setFieldValue('loanPeriod', data.LoanPeriod);        // Loan Period (Months)
    this.setFieldValue('loanRate', data.LoanRate);            // Loan Rate (%)
    this.setFieldValue('monthlyInstallment', data.MonthlyInstallment); // Monthly Installment ($)
  
    // Finance Company Names
    this.setFieldValue('financeCompany1', data.FinanceCompanyName1);
    this.setFieldValue('financeCompany2', data.FinanceCompanyName2);
    this.setFieldValue('financeCompany3', data.FinanceCompanyName3);
    this.setFieldValue('financeCompany4', data.FinanceCompanyName4);
  
    // P -> Pending, A -> Approved, R -> Rejected
    const mapStatus = (code: string | null): string => {
      if (!code) return '';
      switch (code) {
        case 'P': return 'Pending';
        case 'A': return 'Approved';
        case 'R': return 'Rejected';
        default: return '';
      }
    };
  
    this.setFieldValue('financeCompanyStatus1', mapStatus(data.FinanceCompany1));
    this.setFieldValue('financeCompanyStatus2', mapStatus(data.FinanceCompany2));
    this.setFieldValue('financeCompanyStatus3', mapStatus(data.FinanceCompany3));
    this.setFieldValue('financeCompanyStatus4', mapStatus(data.FinanceCompany4));
  
    this.setFieldValue('financeProblem', !!data.FinanceStatus);
  
    this.setFieldValue('loanSubmittedDate', convertDateToYYYYMMDD(data.ApplicationDate));
    this.setFieldValue('loanStatusDate', convertDateToYYYYMMDD(data.LoanStatusDate));
  
    this.setFieldValue('remark', data.Remark);
    this.setFieldValue('loanStatus', data.LoanStatus);
  
   this.updateFieldLock('financeCompanyStatus1', data.FinanceCompanyName1 !== null);
    this.updateFieldLock('financeCompanyStatus2', data.FinanceCompanyName2 !== null);
    this.updateFieldLock('financeCompanyStatus3', data.FinanceCompanyName3 !== null);
    this.updateFieldLock('financeCompanyStatus4', data.FinanceCompanyName4 !== null);
  }

  private setFieldValue(fieldId: string, value: any): void {
    const field = this.formFields.find(f => f.id === fieldId);
    if (field) {
      field.value = value;
    } else {
      console.warn(`Field ${fieldId} not found.`);
    }
  }
  
  private updateFieldLock(fieldId: string, unlock: boolean): void {
    const field = this.formFields.find(f => f.id === fieldId);
    if (field) {
      field.lock = !unlock; 
    } else {
      console.warn(`Field ${fieldId} not found.`);
    }
  }
  
  onFieldChange(event: any): void {
    if (event?.fields && Array.isArray(event.fields)) {
      event.fields.forEach((f: { id: string, value: any }) => {
      });
  
      this.updateMonthlyInstallment();
    } else {
      console.warn('No detected fields');
    }
  }
  
  private updateMonthlyInstallment(): void {
    
    const buyersOrderNumber = this.formFields.find(f => f.id === 'buyersOrderNumber')?.value || '';
    const loanAmount = parseFloat(this.formFields.find(f => f.id === 'loanAmount')?.value || '0');
    const loanPeriod = parseFloat(this.formFields.find(f => f.id === 'loanPeriod')?.value || '0');
    const loanRate = parseFloat(this.formFields.find(f => f.id === 'loanRate')?.value || '0');
  
    const payload = {
      LoanAmount: loanAmount,
      BuyersOrderNumber: buyersOrderNumber,
      LoanPeriod: loanPeriod,
      LoanRate: loanRate
    };
  
    this.loanStatusService.changeLoanAmount(payload).subscribe({
      next: (response) => {
       
        const monthlyInstallment = response?.body?.MonthlyInstallment;
  
        if (monthlyInstallment !== undefined) {
          const installmentField = this.formFields.find(f => f.id === 'monthlyInstallment');
          if (installmentField) {
            installmentField.value = monthlyInstallment;
          } else {
            console.warn('Field "monthlyInstallment" not found on formFields.');
          }
        } else {
          console.warn('MonthlyInstallment value not found on API\'s response.');
        }
      },
      error: (error) => {
        console.error('changeLoanAmount API error:', error);
      }
    });
  }

  updateEntry(): void {
    const getFieldValue = (fieldId: string) => this.formFields.find(f => f.id === fieldId)?.value || null;
    const getLockStatus = (fieldId: string) => this.formFields.find(f => f.id === fieldId)?.lock || true;
  
    const formatDate = (date: string): string => {
      if (!date) return '';
      const [year, month, day] = date.split('-'); 
      return `${day}/${month}/${year}`;
    };
  
    const payload = {
      model: {
        __type: "KAPS.Model.Sales.CrmLoanStatus",
        RefNo: getFieldValue('buyersOrderNumber'),
        NrIc: getFieldValue('nricNumber'),
        Name: getFieldValue('nameAsInNRIC'),
        LoanAmount: parseFloat(getFieldValue('loanAmount')),
        LoanPeriod: parseInt(getFieldValue('loanPeriod')),
        LoanRate: parseFloat(getFieldValue('loanRate')),
        MonthlyInstallment: parseFloat(getFieldValue('monthlyInstallment')),
        FinanceCompany1: getFieldValue('financeCompanyStatus1')?.charAt(0) || null,
        FinanceCompany2: getFieldValue('financeCompanyStatus2')?.charAt(0) || null,
        FinanceCompany3: getFieldValue('financeCompanyStatus3')?.charAt(0) || null,
        FinanceCompany4: getFieldValue('financeCompanyStatus4')?.charAt(0) || null,
        FinanceCompanyName1: getFieldValue('financeCompany1'),
        FinanceCompanyName2: getFieldValue('financeCompany2'),
        FinanceCompanyName3: getFieldValue('financeCompany3'),
        FinanceCompanyName4: getFieldValue('financeCompany4'),
        DisableFinanceCompany1: getLockStatus('financeCompanyStatus1'),
        DisableFinanceCompany2: getLockStatus('financeCompanyStatus2'),
        DisableFinanceCompany3: getLockStatus('financeCompanyStatus3'),
        DisableFinanceCompany4: getLockStatus('financeCompanyStatus4'),
        FinanceStatus: !!getFieldValue('financeProblem'),
        ApplicationDate: formatDate(getFieldValue('loanSubmittedDate')),
        LoanStatusDate: formatDate(getFieldValue('loanStatusDate')),
        Remark: getFieldValue('remark'),
        LoanStatus: getFieldValue('loanStatus'),
      }
    };
  
    this.loanStatusService.updateLoanStatus(payload).subscribe({
      next: (response: { body: any }) => {
        this.notifier.notify('success', 'Loan status updated successfully!');
      },
      error: (error: any) => {
        console.error('API error:', error);
        this.notifier.notify('error', 'Failed to update loan status.');
      }
    });
  }
  
}
