import { Component, OnInit } from "@angular/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { PriceListTemplateReportService } from "src/app/services/sales/price-list-template-report.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-price-list-template-report",
  templateUrl: "./price-list-template-report.component.html",
  styleUrls: ["./price-list-template-report.component.css"],
})
export class PriceListTemplateReportComponent implements OnInit {
  faPrint = faPrint;

  formFields: FormField[] = [
    {
      type: "select",
      id: "format",
      label: "Select format",
      value: "EXCEL",
      options: [
        { value: "PDF", display: "PDF" },
        { value: "EXCEL", display: "EXCEL" },
        { value: "BOTH", display: "BOTH" },
      ],
    },
  ];

  constructor(
    private priceListTemplateReportService: PriceListTemplateReportService,
    private notifier: NotifierService,
  ) {}

  ngOnInit(): void {}

  printPassengerPriceList(): void {
    this.priceListTemplateReportService
      .printPassengerPriceList()
      .subscribe({
        next: (response) => {
          this.handleReportResponse(response);
        },
        error: (error) => {
          console.error(
            "Error generating Passenger Price List:",
            error,
          );
          this.notifier.notify(
            "error",
            "Error generating Passenger Price List",
          );
        },
      });
  }

  printCommercialPriceList(): void {
    this.priceListTemplateReportService
      .printCommercialPriceList()
      .subscribe({
        next: (response) => {
          this.handleReportResponse(response);
        },
        error: (error) => {
          console.error(
            "Error generating Commercial Price List:",
            error,
          );
          this.notifier.notify(
            "error",
            "Error generating Commercial Price List",
          );
        },
      });
  }

  printSTAREXFlyerEng(): void {
    this.priceListTemplateReportService
      .printSTAREXFlyerEng()
      .subscribe({
        next: (response) => {
          this.handleReportResponse(response);
        },
        error: (error) => {
          console.error("Error generating STAREX Flyer:", error);
          this.notifier.notify(
            "error",
            "Error generating STAREX Flyer",
          );
        },
      });
  }

  private handleReportResponse(response: any): void {
    const format = this.getFieldValue("format");
    switch (format) {
      case "PDF":
        if (response.body?.Name) {
          window.open(response.body.Name, "_blank");
        } else {
          this.notifier.notify("error", "PDF file is not available.");
        }
        break;
      case "EXCEL":
        if (response.body?.Value) {
          window.open(response.body.Value, "_blank");
        } else {
          this.notifier.notify(
            "error",
            "Excel file is not available.",
          );
        }
        break;
      case "BOTH":
        if (response.body?.Name) {
          window.open(response.body.Name, "_blank");
        } else {
          this.notifier.notify("error", "PDF file is not available.");
        }
        if (response.body?.Value) {
          window.open(response.body.Value, "_blank");
        } else {
          this.notifier.notify(
            "error",
            "Excel file is not available.",
          );
        }
        break;
    }
  }

  private getFieldValue(id: string): any {
    const field = this.formFields.find((f) => f.id === id);
    return field?.value || "";
  }
}
