import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class EmailBlackListService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets all email blacklist entries.
   * @returns An Observable of the email blacklist.
   */
  getAllEmailBlackList(): Observable<any> {
    return this.http.get('/EmailBlackListService.asmx/GetAllEmailBlackList');
  }

  /**
   * Gets email blacklist detail by ID.
   * @param id - The ID of the email blacklist entry.
   * @returns An Observable of the email detail.
   */
  getEmailDetail(id: string): Observable<any> {
    const encodedId = encodeURIComponent(`"${id}"`);
    const url = `/EmailBlackListService.asmx/GetEmailDetail?id=${encodedId}`;
    return this.http.get(url);
  }

  /**
   * Updates email blacklist entry.
   * @param data - The email data to update.
   * @returns An Observable for the result of the update operation.
   */
  updateEmail(data: { ID: string, Email: string }): Observable<any> {
    return this.http.post('/EmailBlackListService.asmx/UpdateEmail', { model: data });
  }

  /**
   * Adds a new email to the blacklist.
   * @param email - The email to add to the blacklist.
   * @returns An Observable for the result of the add operation.
   */
  addEmail(email: string): Observable<any> {
    return this.http.post('/EmailBlackListService.asmx/AddEmail', { model: { Email: email } });
  }

  /**
   * Deletes selected email blacklist entries.
   * @param items - The list of email blacklist entries to delete.
   * @returns An Observable for the result of the delete operation.
   */
  deleteEmail(items: { Email: string, ID: string, IsChecked: boolean }[]): Observable<any> {
    return this.http.post('/EmailBlackListService.asmx/DeleteEmail', { models: items });
  }
}
