import { Component, OnInit } from "@angular/core";
import { faPrint, faEye } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { OutstandingCoeBiddingService } from "src/app/services/sales-reports/outstanding-coe-bidding.service";
import { model } from "src/app/models/sales-reports/outstanding-coe-bidding";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-outstanding-coe-bidding",
  templateUrl: "./outstanding-coe-bidding.component.html",
  styleUrls: ["./outstanding-coe-bidding.component.css"],
})
export class OutstandingCoeBiddingComponent implements OnInit {
  faPrint = faPrint;
  faEye = faEye;

  FormFields: FormField[] = [
    {
      type: "select",
      id: "SummarizeInformation",
      label: "Summarize information by",
      options: [
        {
          display: "--None--",
          value: "None",
        },
        {
          display: "Customer Name",
          value: "CustomerName",
        },
        {
          display: "Model",
          value: "Model",
        },
        {
          display: "Salesman Code",
          value: "SalesmanCode",
        },
      ],
      value: "None",
    },
    {
      type: "select",
      id: "Show",
      label: "Show",
      options: [
        {
          display: "My buyers order",
          value: "BuyerOrder",
        },
        {
          display: "My team's buyers order",
          value: "TeamBuyerOrder",
        },
        {
          display: "All sales contracts",
          value: "All",
        },
      ],
      value: "BuyerOrder",
    },
    {
      type: "select",
      id: "Format",
      label: "Select format",
      options: [
        { display: "PDF", value: "pdf" },
        { display: "EXCEL", value: "excel" },
        { display: "BOTH", value: "both" },
      ],
      value: "pdf",
    },
  ];

  TableHeaders = [
    {
      title: "CONTRACT DATE",
      type: "data",
      id: "CONTRACTDATE",
    },
    {
      title: "SALES CONTRACT NO.",
      type: "link",
      id: "CONTRACTNO",
    },
    {
      title: "CUSTOMER NAME",
      type: "data",
      id: "CUSTOMERNAME",
    },
    {
      title: "MODEL",
      type: "data",
      id: "MODEL",
    },
    {
      title: "VIN NO.",
      type: "data",
      id: "CASENUMBER",
    },
    {
      title: "COLOR CODE",
      type: "data",
      id: "COLORCODE",
    },
    {
      title: "SMAN CODE",
      type: "data",
      id: "SALESMANCODE",
    },
    {
      title: "CHASSIS NO.",
      type: "data",
      id: "CHASSISNUMBER",
    },
    {
      title: "ENGINE NO.",
      type: "data",
      id: "ENGINENUMBER",
    },
    {
      title: "ALLOCATION DATE",
      type: "data",
      id: "ALLOCATIONDATE",
    },
    {
      title: "REMARKS",
      type: "data",
      id: "REMARKS",
    },
  ];

  TableEntries: {
    ModelGroup: string;
    OutstandingCOEBidding: model[];
  }[] = [];

  constructor(
    private outstandingCoeBiddingService: OutstandingCoeBiddingService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {}

  preview(): void {
    const SummarizeInformation = this.getField(
      "SummarizeInformation",
    ).value;
    const Show = this.getField("Show").value;
    this.outstandingCoeBiddingService
      .PreviewOutstandingCOEBidding(Show, SummarizeInformation)
      .subscribe({
        next: (response) => {
          this.TableEntries = response.body;
        },
        error: console.error,
      });
  }

  print(): void {
    const SummarizeInformation = this.getField(
      "SummarizeInformation",
    ).value;
    const Show = this.getField("Show").value;
    const Format = this.getField("Format").value;

    this.outstandingCoeBiddingService
      .PrintOutstandingCOEBidding(Show, SummarizeInformation)
      .subscribe({
        next: ({ body }) => {
          if (["pdf", "both"].includes(Format)) {
            window.open(body.Name, "_blank");
          }
          if (["excel", "both"].includes(Format)) {
            window.open(body.Value, "_blank");
          }
        },
        error: console.error,
      });
  }

  openEntry(entry?: string) {
    // COMPLETE THIS WHEN AUTOPAC / CONTRACT MANAGEMENT PAGE IS DONE
    /* this.router.navigate(["../autopac/contract-management", entry], {
      relativeTo: this.route,
    }); */
  }

  returnZero() {
    return 0;
  }

  getField(id: string): FormField {
    return (
      this.FormFields.find((f) => f.id === id) || {
        id: "",
        type: "text",
        label: "",
        value: null,
      }
    );
  }
}
