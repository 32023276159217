import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService, HttpResponse } from "../http-util.service";
import { file } from "src/app/models/sales-reports/document-download";

@Injectable({
  providedIn: "root",
})
export class FinalShortPaymentService {
  constructor(private http: HttpUtilService) {}

  FinalShortPaymentReport(): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/FinalShortPaymentReport",
      {},
    );
  }
}
