<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Download Documents</h1>
    <app-navpath />
  </div>
  <button class="shorter-btn add-btn" (click)="openUpload(true)">
    <fa-icon [icon]="faUpload" class="mr-1"></fa-icon>Upload Files
  </button>
  <div class="content-container" *ngFor="let group of groupList">
    <h2 class="sub-heading w-full border-b">{{ group }}</h2>
    <app-data-table
      [tableHeaders]="tableHeaders"
      [tableEntries]="handleGroup(group)"
      [pagination]="false"
      (remove)="openDelete(true, $event, group)"
      (download)="downloadFile($event, group)"
    ></app-data-table>
  </div>
</section>
<div class="bg-modal" *ngIf="showDelete">
  <div class="container-confirmation">
    <div class="flex flex-col justify-between">
      <div for="" class="mb-8 flex flex-col gap-4">
        <h2 class="text-lg">Confirm Action</h2>
        <p>Are you sure you want to delete this document?</p>
      </div>
      <div class="ml-auto mr-0 mt-4 flex w-fit gap-4">
        <button class="shorter-btn save-btn" (click)="removeEntry()">
          Yes
        </button>
        <button
          class="shorter-btn delete-btn"
          (click)="openDelete(false)"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="bg-modal" *ngIf="showUpload">
  <div class="container-upload">
    <h2 class="sub-heading w-full border-b">Manage Documents</h2>
    <app-data-form [dataFields]="documentsForm" />
    <label class="block px-8 text-sm font-bold">
      Select file
      <input
        type="file"
        name=""
        id=""
        (change)="onFileChange($event)"
        class="ml-16"
      />
    </label>
    <div class="flex w-full justify-end gap-2 border-t pt-4">
      <button class="shorter-btn add-btn" (click)="uploadFile()">
        <fa-icon [icon]="faUpload" class="mr-1"></fa-icon>Upload
      </button>
      <button
        class="shorter-btn clear-btn"
        (click)="openUpload(false)"
      >
        <fa-icon [icon]="faCancel" class="mr-1"></fa-icon>Cancel
      </button>
    </div>
  </div>
</div>
