import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class VehicleStockReportService {
  private readonly apiUrl: string = "AutopacReportService.asmx/VehicleStockReport";

  constructor(private http: HttpUtilService) {}

  /**
   * Retrieves the vehicle stock report based on the specified parameters.
   * @param toDate The end date for the report in the format "dd/MM/yyyy".
   * @param carType The type of car for the report 
   * @param stockType The type of stock to report
   * @returns An Observable containing the response from the API.
   */
  getVehicleStockReport(toDate: string, carType: string, stockType: string): Observable<any> {
    const payload = {
      model: {
        ToDate: toDate,
        CarType: carType,
        StockType: stockType,
      },
    };

    return this.http.post(this.apiUrl, payload);
  }
}
