import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class OutstandingCarAllocationService {
  constructor(private http: HttpUtilService) {}

  PreviewOutstandingCarAllocation(): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/PreviewOutstandingCarAllocation",
      {
        model: {
          Type: "",
        },
      },
    );
  }

  OutstandingCarAllocation(): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/OutstandingCarAllocation",
      {
        model: {
          Type: "",
        },
      },
    );
  }
}
