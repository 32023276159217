import { Component, Input, OnInit } from "@angular/core";
import {
  faAngleDown,
  faAngleUp,
  faCar,
  faCarSide,
  faChartLine,
  faCogs,
  faHandHoldingDollar,
  faMicrochip,
  faPrint,
  faTablet,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";
import { Dropdown, DropdownOptions, initFlowbite } from "flowbite";
import { GENERIC_PROFILE_PIC, STORAGE_KEYS } from "../../constants";
import { UserInfoService } from "../../services/user-info.service";
import { HomeService } from "src/app/services/home/home.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Company } from "../../models/company/company-model";
import { SecurityService } from "../../services/security/security.service";
import { LoadingService } from "../../services/loading.service";
import {
  AlertService,
  MessageType,
} from "../../services/alert.service";
import { environment } from "src/environments/environment";
import { Module } from "src/app/interfaces/modules";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidenavbar",
  templateUrl: "./sidenavbar.component.html",
  styleUrls: ["./sidenavbar.component.css"],
})
export class SidenavbarComponent implements OnInit {
  @Input()
  showSideMenu!: boolean;
  //Icons imports
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  displayedModule: string = "";
  displayedSubModule: string = "Booking";
  userInfo: { [key: string]: any } = {};
  profilePic: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(
    GENERIC_PROFILE_PIC,
  );
  environment = environment;

  menus: any[] = [];
  selectedMenu: any[] = [];
  modules: any[] = [];
  notPageSubmodules = [
    {
      name: "Outstanding Registration",
      path: "SalesReportService.asmx/OutstandingRegistration",
    },
  ];
  module_icons = [
    faCogs,
    faUser,
    faPrint,
    faUser,
    faCar,
    faChartLine,
    faPrint,
    faCarSide,
    faMicrochip,
    faPrint,
    faTablet,
  ];
  menuName: string = "";

  departmentsBtns = [
    {
      color: "regular-btn sales-btn",
      icon: faHandHoldingDollar,
      text: "Sales",
      menu_id: "KAPS",
    },
    {
      color: "regular-btn hr-btn",
      icon: faUsers,
      text: "HR",
      menu_id: "ELEAVE",
    },
    {
      color: "regular-btn cd-btn",
      icon: faCar,
      text: "C&D",
      menu_id: "ONLINESALES",
    },
  ];
  companyList: Company[] = [];
  selectedCompany: Company | undefined;

  // Declare dropdown items
  dropdown: Dropdown | null = null;

  protected readonly STORAGE_KEYS = STORAGE_KEYS;

  constructor(
    private userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private securityService: SecurityService,
    private sanitizer: DomSanitizer,
    private homeService: HomeService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.updateUserInfo();
    this.switchMenu(this.menuName);
    initFlowbite();
    const $targetEl: HTMLElement | null =
      document.getElementById("dropdownMenu");
    const $triggerEl: HTMLElement | null =
      document.getElementById("dropdownButton");
    const options: DropdownOptions = { triggerType: "click" };
    if ($targetEl && $triggerEl) {
      this.dropdown = new Dropdown($targetEl, $triggerEl, options);
    }
    this.getCurrentRoute();
  }

  switchMenu(menuName: string) {
    menuName = menuName || "KAPS";
    this.getMenus(menuName);
  }

  toggleModuleDropdown(moduleName: string) {
    this.displayedModule =
      this.displayedModule == moduleName.toLowerCase()
        ? ""
        : moduleName.toLowerCase();
  }

  getMenus(menu_id: string) {
    this.homeService.getLoginUser().subscribe((response) => {
      const menuKeys = Object.keys(
        response.body.user.permissions.menus,
      );

      menuKeys.forEach((key) => {
        const menu = response.body.user.permissions.menus[key];
        this.menus.push(menu);
      });

      this.selectedMenu = this.menus.filter(
        (menu) => menu.menu_id === menu_id,
      );
      this.modules = this.selectedMenu[0].modules;

      this.modules = this.modules.map((module, index) => ({
        ...module,
        icon: this.module_icons[index] || faCogs,
      }));
    });
  }

  showError() {
    alert(
      "Error: Mapping has not been set up for this submodule yet. Please contact the administrator.",
    );
  }

  updateUserInfo() {
    this.userInfoService.userInfo$.subscribe((info) => {
      this.userInfo = info;

      // set profile pic
      const profilePic =
        this.userInfo[
          STORAGE_KEYS.USER_INFO["PROFILE_PIC"].sessionKey
        ] ?? GENERIC_PROFILE_PIC;
      this.profilePic =
        this.sanitizer.bypassSecurityTrustUrl(profilePic);

      // set company info
      const selectedCompanyCode =
        this.userInfo[
          STORAGE_KEYS.USER_INFO["SELECTED_COMPANY"].sessionKey
        ] ?? "";
      this.companyList =
        (this.userInfo[
          STORAGE_KEYS.USER_INFO["COMPANIES"].sessionKey
        ] as Company[]) ?? [];

      // sanitize company images
      this.companyList = this.companyList.map((company) =>
        this.sanitizeCompanyImage(company),
      );
      this.selectedCompany = this.companyList.find(
        (company) => company.company_code === selectedCompanyCode,
      );
    });
  }

  sanitizeCompanyImage(company: Company) {
    if (company?.logo && typeof company?.logo === "string") {
      company.logo = this.sanitizer.bypassSecurityTrustUrl(
        company.logo ?? "",
      );
    }
    if (
      company?.small_logo &&
      typeof company?.small_logo === "string"
    ) {
      company.small_logo = this.sanitizer.bypassSecurityTrustUrl(
        company.small_logo ?? "",
      );
    }
    return company;
  }

  onSelectCompany(company: Company) {
    this.loadingService.startLoading("company");
    this.selectedCompany = company;
    this.securityService
      .switchCompany(company.company_code)
      .subscribe({
        next: (response) => {
          if (response.body?.allowed) {
            // hide element
            if (this.dropdown) {
              this.dropdown.hide();
            }
            this.userInfoService.setUserInfo(
              STORAGE_KEYS.USER_INFO["SELECTED_COMPANY"].sessionKey,
              company.company_code,
            );
            // reload page
            window.location.reload();
          } else {
            console.error(
              "Error switching company:",
              response.message,
            );
            this.alertService.showMessage(
              response.message,
              MessageType.Error,
            );
          }
        },
        error: (error) => {
          console.error("Error switching company:", error);
          this.alertService.showMessage(error, MessageType.Error);
        },
        complete: () => {
          this.loadingService.stopLoading("company");
        },
      });
  }

  getCurrentRoute(): void {
    const urlSegments = this.router.url
      .split("/")
      .filter((segment) => segment);
    if (urlSegments.length >= 2) {
      this.displayedModule = urlSegments[0].replace(/-/g, " ");
      this.displayedSubModule = urlSegments[1].replace(/-/g, " ");
    }
  }

  openRoute(route: string, name: string): void {
    if (this.isNotPageSubmodule(name)) {
      const selectedPath = this.notPageSubmodules.find(
        (item) => item.name == name,
      )?.path;
      window.open(
        environment.KAPS_API_BACKEND_URL + selectedPath,
        "_blank",
      );
    } else {
      this.router.navigate([route]);
    }
  }

  isNotPageSubmodule(name: string): boolean {
    return this.notPageSubmodules.some(
      (submodule) => submodule.name === name,
    );
  }
}
