import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class OutstandingCoeBiddingService {
  constructor(private http: HttpUtilService) {}

  PreviewOutstandingCOEBidding(
    Show: string,
    SummarizeInformation: string,
  ): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/PreviewOutstandingCOEBidding",
      {
        model: {
          DateField: "Allocation Date",
          Range: "Custom",
          Show,
          SummarizeInformation,
        },
      },
    );
  }

  PrintOutstandingCOEBidding(
    Show: string,
    SummarizeInformation: string,
  ): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/PrintOutstandingCOEBidding",
      {
        model: {
          DateField: "Allocation Date",
          Range: "Custom",
          Show,
          SummarizeInformation,
        },
      },
    );
  }
}
