import { Component, OnInit } from "@angular/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { formatDate } from "@angular/common";
import { NotifierService } from "angular-notifier";
import { FinalShortPaymentService } from "src/app/services/sales-reports/final-short-payment";

@Component({
  selector: "app-final-short-payment",
  templateUrl: "./final-short-payment.component.html",
  styleUrls: ["./final-short-payment.component.css"],
})
export class FinalShortPaymentComponent implements OnInit {
  faPrint = faPrint;

  FormFields: FormField[] = [
    {
      type: "select",
      id: "Format",
      label: "Select format",
      options: [
        { display: "PDF", value: "pdf" },
        { display: "EXCEL", value: "excel" },
        { display: "BOTH", value: "both" },
      ],
      value: "pdf",
    },
  ];
  constructor(
    private notifier: NotifierService,
    private finalShortPaymentService: FinalShortPaymentService,
  ) {}

  ngOnInit() {}

  print(): void {
    const Format = this.getField("Format").value;

    this.finalShortPaymentService
      .FinalShortPaymentReport()
      .subscribe({
        next: ({ body }) => {
          if (body.Code == -1) {
            console.log(body.Message);
          } else {
            if (["pdf", "both"].includes(Format)) {
              window.open(body.Name, "_blank");
            }
            if (["excel", "both"].includes(Format)) {
              window.open(body.Value, "_blank");
            }
          }
        },
        error: console.error,
      });
  }

  getField(id: string): FormField {
    return (
      this.FormFields.find((f) => f.id === id) || {
        id: "",
        type: "text",
        label: "",
        value: null,
      }
    );
  }
}
