import { Component, OnInit } from "@angular/core";
import {
  faUpload,
  faCancel,
  faTrash,
  faXmark,
  faCheck,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { SpecialPriceListMasterService } from "src/app/services/sales/special-price-list-master.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-manage-files",
  templateUrl: "./manage-files.component.html",
  styleUrls: ["./manage-files.component.css"],
})
export class ManageFilesComponent implements OnInit {
  constructor(
    private specialPriceListMasterService: SpecialPriceListMasterService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
  ) {}

  faUpload = faUpload;
  faCancel = faCancel;
  faTrash = faTrash;
  faXmark = faXmark;
  faCheck = faCheck;

  MainEntries: any[] = [];

  MainHeaders = [
    { title: "FILE NAME", type: "data", id: "Name" },
    {
      title: "DOWNLOAD",
      type: "save",
      id: "Download",
      Hclasses: "text-center",
    },
  ];

  SecondaryHeaders = [
    "NAME",
    "SIZE",
    "PROGRESS",
    "STATUS",
    "ACTIONS",
  ];

  SecondaryEntries: any[] = [];

  files: File[] = [];

  totalProgress: number = 0;

  ngOnInit() {
    this.getAllFiles();
  }

  private getAllFiles(): void {
    this.specialPriceListMasterService.GetAllFiles().subscribe(
      (response) => {
        this.MainEntries = response.body.map((obj: any) => {
          const newObj: any = {
            Name: obj.Name,
            Download: "",
          };
          return newObj;
        });
      },
      (error) => {
        console.error("Error fetching data:", error);
      },
    );
  }

  openFile(event: any): void {
    const file = encodeURIComponent(event.Name);
    window.open(
      `${environment.KAPS_API_BACKEND_URL}SpecialPriceListMasterService.asmx/DownloadFile?filename=${file}`,
    );
  }

  uploadEntries(entry?: string): void {
    let uploadedFiles: any[] = [];
    if (entry) {
      uploadedFiles = this.files.filter((item) => item.name == entry);
    } else {
      uploadedFiles = this.files;
    }
    uploadedFiles.forEach((file) => {
      this.specialPriceListMasterService
        .UploadFiles(file, file.name)
        .subscribe({
          next: (response) => {
            if (response.body.Code === 0) {
              let activeEntry = this.SecondaryEntries.find(
                (item) => item.Name == file.name,
              );
              activeEntry.Status = true;
              activeEntry.Progress = 1;
              this.changeProgress();
              this.notifier.notify(
                "success",
                "Document has been uploaded successfully",
              );
            } else {
              this.notifier.notify("error", response.body.message);
            }
          },
          error: console.error,
        });
    });
  }

  private changeProgress(): void {
    const totalProgress = this.SecondaryEntries.reduce(
      (total, item) => total + (item.Progress || 0),
      0,
    );

    const entriesLength = this.SecondaryEntries.length;
    this.totalProgress =
      entriesLength > 0 ? totalProgress / entriesLength : 0;
  }

  isCancelDisabled(): boolean {
    return (
      this.SecondaryEntries.length === 0 ||
      this.SecondaryEntries.every((item) => item.Status)
    );
  }

  onFileChange(event: any): void {
    this.files = [];
    this.SecondaryEntries = [];

    const target = event.target as HTMLInputElement;

    if (!target.files || target.files.length === 0) {
      console.log("No file selected");
      return;
    }

    const selectedFiles = Array.from(target.files);
    this.files = [...this.files, ...selectedFiles];

    selectedFiles.forEach((file) => {
      const newEntry = {
        Name: file.name,
        Size: `${(file.size * Math.pow(10, -6)).toFixed(2)} MB`,
      };

      this.SecondaryEntries = [...this.SecondaryEntries, newEntry];

      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const arrayBuffer: ArrayBuffer = e.target.result;
      };

      reader.readAsArrayBuffer(file);
    });
  }

  removeEntry(entry?: any): void {
    if (entry) {
      this.SecondaryEntries = this.SecondaryEntries.filter(
        (item) => item.Name !== entry,
      );
      this.files = this.files.filter((item) => item.name !== entry);
    } else {
      this.SecondaryEntries = [];
      this.files = [];
    }
  }
}
