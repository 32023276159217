<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">All Invoices</h1>
      <app-navpath />
    </div>
    <div class="content-container">
      <h2 class="sub-heading">All Invoices Report</h2>
      <app-data-form [dataFields]="formFields"></app-data-form>
      <div class="mb-2 mt-4 flex w-fit gap-1">
        <button class="shorter-btn misc-btn" (click)="printAllInvoices()">
          <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Print
        </button>
      </div>
    </div>
</section>
  