import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailBlackListService } from 'src/app/services/sales/email-black-list.service';
import { FormField } from '../../../../interfaces/form-field.model';
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid, isEmptyGuid } from '../../../../services/util.service';
import { faPlus, faPencil, faBan } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-email-black-list-detail',
  templateUrl: './email-black-list-detail.component.html',
  styleUrls: ['./email-black-list-detail.component.css']
})
export class EmailBlackListDetailComponent implements OnInit {

  // Icons imports
  faPlus = faPlus;
  faPencil = faPencil;
  faBan = faBan;
  
  mode: string = "Add";
  emailId: string = getEmptyGuid();

  formFields: FormField[] = [
    { 
      type: 'text', 
      id: 'email', 
      label: 'Email', 
      value: '' 
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailBlackListService: EmailBlackListService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.emailId = params['id'];

      if (!isEmptyGuid(this.emailId)) {
        this.mode = 'Edit';
        this.loadEmailDetail();
      }
    });
  }

  private loadEmailDetail(): void {
    this.emailBlackListService.getEmailDetail(this.emailId).subscribe({
      next: (response) => {
        const data = response.body;
        if (data && data.Email) {
          const emailField = this.formFields.find(f => f.id === 'email');
          if (emailField) {
            emailField.value = data.Email;
          }
        } else {
          console.error("Error: Incorrect structure.");
        }
      },
      error: (error) => {
        console.error("Error loading email detail:", error);
        this.notifier.notify('error', 'Error loading email detail');
      }
    });
  }
  
  onSave(): void {
    if (this.mode === 'Add') {
      this.addEmail();
    } else if (this.mode === 'Edit') {
      this.updateEmail();
    }
  }

  private addEmail(): void {
    const newEmailData = {
      Email: this.getFieldValue('email')
    };

    this.emailBlackListService.addEmail(newEmailData.Email).subscribe({
      next: () => {
        this.notifier.notify('success', 'Email added successfully');
        this.router.navigate(['../../email-black-list'], { relativeTo: this.route });
      },
      error: (error) => {
        console.error("Error adding email:", error);
        this.notifier.notify('error', 'Error adding email');
      }
    });
  }

  private updateEmail(): void {
    const updatedEmailData = {
      ID: this.emailId,
      Email: this.getFieldValue('email')
    };

    this.emailBlackListService.updateEmail(updatedEmailData).subscribe({
      next: () => {
        this.notifier.notify('success', 'Email updated successfully');
        this.router.navigate(['../../email-black-list'], { relativeTo: this.route });
      },
      error: (error) => {
        console.error("Error updating email:", error);
        this.notifier.notify('error', 'Error updating email');
      }
    });
  }

  private getFieldValue(id: string): any {
    const field = this.formFields.find(f => f.id === id);
    return field?.value || '';
  }

  onCancel(): void {
    this.router.navigate(['../../email-black-list'], { relativeTo: this.route });
  }
}
