import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import {
  faPencil,
  faTrash,
  faInfoCircle,
  faAngleDown,
  faAngleUp,
  faPrint,
  faSave,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.css"],
})
export class DataTableComponent implements OnInit, OnChanges {
  /* IMPORTED TABLE DATA */

  @Input() tableHeaders: any[] = [];
  filteredHeaders: any[] = [];
  @Input() tableEntries: any[] = [];
  @Input() searchInput: string = "";
  @Input() pagination: boolean = true;
  @Input() sorting: boolean = false;
  @Output() edit = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() open = new EventEmitter<any>();
  @Output() check = new EventEmitter<any>();
  @Output() print = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();
  @Output() download = new EventEmitter<any>();

  // Paginator variables
  pageSize: number = 5;
  pageIndex: number = 0;
  pageTop!: number;
  pageEvent: PageEvent | any;
  pageSizeOptions = [5, 25, 50];

  //Icons
  faPencil = faPencil;
  faTrash = faTrash;
  faInfoCircle = faInfoCircle;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faPrint = faPrint;
  faSave = faSave;
  faDownload = faDownload;

  //Sorting variables
  sortingHeader = "";
  sortingAsc = true;
  sortingDirection: { [key: string]: boolean } = {};

  //Paginator update function
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    if (
      this.pageSize * (this.pageIndex + 1) >
      this.tableEntries.length
    ) {
      this.pageTop = this.tableEntries.length;
    } else {
      this.pageTop = this.pageSize * (this.pageIndex + 1);
    }
  }

  returnZero() {
    return 0;
  }

  editFunction(value: any) {
    this.edit.emit(value);
  }

  removeFunction(value: any) {
    this.remove.emit(value);
  }

  openFunction(value: any) {
    this.open.emit(value);
  }

  printFunction(value: any) {
    this.print.emit(value);
  }

  saveFunction(value: any) {
    this.save.emit(value);
  }

  downloadFunction(value: any) {
    this.download.emit(value);
  }

  checkFunction(index: number, subIndex: string) {
    const entry = this.tableEntries[index][subIndex];
    if (this.isObj(entry)) {
      this.tableEntries[index][subIndex].value = !entry.value;
    } else {
      this.tableEntries[index][subIndex] = !entry;
    }
    this.check.emit(this.tableEntries);
  }

  disabledCheckbox(index: number, subIndex: string): boolean {
    const data = this.tableEntries[index][subIndex];
    if (this.isObj(data) && data.clicked !== undefined) {
      return data.clicked;
    } else {
      return false;
    }
  }

  isObj(val: any): boolean {
    return typeof val === "object";
  }

  ngOnInit(): void {
    this.filteredHeaders = this.tableHeaders.filter(
      (item) => item.type !== "hidden",
    );
    if (this.pagination) {
      this.pageTop = this.pageSize;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.pagination) {
      this.pageTop = this.tableEntries.length;
    }
  }

  filterEntry(user: any): {} {
    const userFiltered: any = {};
    for (const key in user) {
      if (key !== "innerId") {
        userFiltered[key] = user[key];
      }
    }
    return userFiltered;
  }

  toggleSort(headerId: string): void {
    if (this.sortingHeader === headerId) {
      this.sortingDirection[headerId] =
        !this.sortingDirection[headerId];
      this.sortingAsc = this.sortingDirection[headerId];
    } else {
      this.sortingHeader = headerId;
      this.sortingAsc = true;
      this.sortingDirection[headerId] = true;
    }
  }

  sortEntries(entries: any[], header: string): any[] {
    if (header !== "" && this.sortingAsc) {
      return entries.sort((a, b) => {
        let propA = "";
        let propB = "";
        if (a[header]) {
          propA = a[header].toString().toLowerCase();
        }
        if (b[header]) {
          propB = b[header].toString().toLowerCase();
        }

        if (propA < propB) {
          return -1;
        }
        if (propA > propB) {
          return 1;
        }
        return 0;
      });
    } else if (header !== "" && !this.sortingAsc) {
      return entries.sort((a, b) => {
        let propA = "";
        let propB = "";
        if (a[header]) {
          propA = a[header].toString().toLowerCase();
        }
        if (b[header]) {
          propB = b[header].toString().toLowerCase();
        }

        if (propA > propB) {
          return -1;
        }
        if (propA < propB) {
          return 1;
        }
        return 0;
      });
    } else {
      return entries;
    }
  }
}
