import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService, HttpResponse } from "../http-util.service";
import {
  model,
  active,
} from "src/app/models/sales/special-price-list-master";

@Injectable({
  providedIn: "root",
})
export class SpecialPriceListMasterService {
  constructor(private http: HttpUtilService) {}

  GetAll(): Observable<HttpResponse<model[]>> {
    return this.http.get("SpecialPriceListMasterService.asmx/GetAll");
  }

  Delete(items: model[]): Observable<any> {
    return this.http.post(
      "SpecialPriceListMasterService.asmx/Delete",
      {
        items,
      },
    );
  }

  GetBasicActiveModelList(): Observable<HttpResponse<active[]>> {
    return this.http.get(
      "ModelSpecificationService.asmx/GetBasicActiveModelList",
    );
  }

  GetDetails(id: string): Observable<HttpResponse<model>> {
    return this.http.get(
      "SpecialPriceListMasterService.asmx/GetDetails",
      {
        params: { id },
      },
    );
  }

  Update(model: model): Observable<HttpResponse<model>> {
    return this.http.post(
      "SpecialPriceListMasterService.asmx/Update",
      {
        model,
      },
    );
  }

  Add(model: any): Observable<HttpResponse<any>> {
    return this.http.post("SpecialPriceListMasterService.asmx/Add", {
      model,
    });
  }

  GetAllFiles(): Observable<HttpResponse<any[]>> {
    return this.http.get(
      "SpecialPriceListMasterService.asmx/GetAllFiles",
    );
  }

  UploadFiles(file: ArrayBuffer, fileName: string): Observable<any> {
    const formData = new FormData();

    const blob = new Blob([file], {
      type: "application/octet-stream",
    });
    formData.append("file", blob, fileName);
    formData.append("docType", "SpecialPriceList");

    return this.http.post("UploadFiles.ashx", formData);
  }
}
