<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Special Price List Master</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex gap-1">
    <button class="shorter-btn add-btn" (click)="openEntry()">
      <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
    </button>
    <button class="shorter-btn delete-btn" (click)="openModal(true)">
      <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Delete
    </button>
    <button class="shorter-btn misc-btn" (click)="openFiles()">
      Manage Files
    </button>
    <app-search-input
      [isTable]="true"
      (dataOutput)="onSearch($event)"
    ></app-search-input>
  </div>
  <div class="content-container">
    <app-data-table
      [tableHeaders]="tableHeaders"
      [tableEntries]="filteredModels"
      [pagination]="false"
      (open)="openEntry($event)"
    ></app-data-table>
  </div>
</section>
<div class="bg-modal" *ngIf="showModal">
  <div class="container-confirmation">
    <div class="flex flex-col justify-between">
      <div for="" class="mb-8 flex flex-col gap-4">
        <h2 class="text-lg">Confirm Action</h2>
        <p>Are you sure you want to delete record(s)?</p>
      </div>
      <div class="ml-auto mr-0 mt-4 flex w-fit gap-4">
        <button class="shorter-btn save-btn" (click)="removeEntry()">
          Yes
        </button>
        <button
          class="shorter-btn delete-btn"
          (click)="openModal(false)"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
