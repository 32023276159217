import { Component, OnInit } from "@angular/core";
import { faPrint, faEye } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { OutstandingCarAllocationService } from "src/app/services/sales-reports/outstanding-car-allocation.service";
import { model } from "src/app/models/sales-reports/outstanding-car-allocation";

@Component({
  selector: "app-outstanding-car-allocation",
  templateUrl: "./outstanding-car-allocation.component.html",
  styleUrls: ["./outstanding-car-allocation.component.css"],
})
export class OutstandingCarAllocationComponent implements OnInit {
  faPrint = faPrint;
  faEye = faEye;

  FormFields: FormField[] = [
    {
      type: "select",
      id: "Format",
      label: "Select format",
      options: [
        { display: "PDF", value: "pdf" },
        { display: "EXCEL", value: "excel" },
        { display: "BOTH", value: "both" },
      ],
      value: "pdf",
    },
  ];

  TableHeaders = [
    {
      title: "SALES CONTRACT NO.",
      type: "data",
      id: "CONTRACTNO",
    },
    {
      title: "CONTRACT DATE",
      type: "data",
      id: "CONTRACTDATEPRE",
    },
    {
      title: "BUYER ORDER NO.",
      type: "data",
      id: "BUYERORDERNO",
    },
    {
      title: "CUSTOMER NAME",
      type: "data",
      id: "CUSTOMERNAME",
    },
    {
      title: "MODEL",
      type: "data",
      id: "MODEL",
    },
    {
      title: "VIN NO.",
      type: "data",
      id: "VINNO",
    },
    {
      title: "COLOR CODE",
      type: "data",
      id: "COLOURCODE",
    },
    {
      title: "SMAN CODE",
      type: "data",
      id: "SALESMANCODE",
    },
    {
      title: "CHASSIS NO.",
      type: "data",
      id: "CHASSISNO",
    },
    {
      title: "ENGINE NO.",
      type: "data",
      id: "ENGINENO",
    },
    {
      title: "ALLOCATION DATE",
      type: "data",
      id: "ALLOCATIONDATE",
    },
    {
      title: "REMARKS",
      type: "data",
      id: "REMARKS",
    },
  ];

  TableEntries: any[] = [];

  constructor(
    private outstandingCarAllocationService: OutstandingCarAllocationService,
  ) {}

  ngOnInit() {}

  preview(): void {
    this.outstandingCarAllocationService
      .PreviewOutstandingCarAllocation()
      .subscribe({
        next: (response) => {
          const allowedKeys = this.TableHeaders.map(
            (header) => header.id,
          );
          response.body.forEach(
            (element: { "ModelGroup": string; "Items": model[] }) => {
              element.Items.forEach((Item: any) => {
                let newItem: any = {};
                allowedKeys.forEach((key) => {
                  if (Item.hasOwnProperty(key)) {
                    newItem[key] = Item[key];
                  }
                });
                this.TableEntries = [newItem, ...this.TableEntries];
              });
            },
          );
        },
        error: console.error,
      });
  }

  print(): void {
    const Format = this.getField("Format").value;

    this.outstandingCarAllocationService
      .OutstandingCarAllocation()
      .subscribe({
        next: ({ body }) => {
          if (["pdf", "both"].includes(Format)) {
            window.open(body.Name, "_blank");
          }
          if (["excel", "both"].includes(Format)) {
            window.open(body.Value, "_blank");
          }
        },
        error: console.error,
      });
  }

  getField(id: string): FormField {
    return (
      this.FormFields.find((f) => f.id === id) || {
        id: "",
        type: "text",
        label: "",
        value: null,
      }
    );
  }
}
