<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Outstanding Car Allocation</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <app-data-form [dataFields]="FormFields" />
    <div class="mt-2 flex gap-x-1">
      <button class="shorter-btn show-btn" (click)="preview()">
        <fa-icon [icon]="faEye" class="mr-1" />Preview
      </button>
      <button class="shorter-btn print-btn" (click)="print()">
        <fa-icon [icon]="faPrint" class="mr-1" />Print
      </button>
    </div>
  </div>
  <div class="content-container" *ngIf="TableEntries.length > 0">
    <app-data-table
      [tableHeaders]="TableHeaders"
      [tableEntries]="TableEntries"
      [pagination]="false"
    />
  </div>
</div>
