import { Component, OnInit } from "@angular/core";
import {
  faPlus,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { EmailBlackListService } from "src/app/services/sales/email-black-list.service";
import { ActivatedRoute, Router } from "@angular/router"; 
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: "app-email-blacklist",
  templateUrl: "./email-black-list-list.component.html",
  styleUrls: ["./email-black-list-list.component.css"],
})
export class EmailBlackListListComponent implements OnInit {

  searchInput: string = "";
  filteredEmails: any[] = [];
  emailBlackList: any[] = []; 

  // Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "EMAIL",
      type: "link",
      id: "email",
    },
    {
      title: "ID",
      type: "hidden",
      id: "id"
    },
  ];

  constructor(
    private emailBlackListService: EmailBlackListService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService 
  ) {}

  ngOnInit() {
    this.emailBlackListService.getAllEmailBlackList().subscribe(
      (response) => {
        if (response && response.body && Array.isArray(response.body)) {
          this.emailBlackList = response.body.map((email: any) => ({
            checked: false,
            email: email.Email || "",
            id: email.ID || ""
          }));
          this.filteredEmails = [...this.emailBlackList];
        } else {
          console.error("Error: Incorrect structure");
        }
      },
      (error) => {
        console.error("Error getting email list", error);
      }
    );
  }
  
  addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(["../email-black-list", emptyGuid], {
      relativeTo: this.route, 
    });
  }

  deleteSelectedEntries(): void {
    const selectedItems = this.filteredEmails.filter(item => item.checked);
  
    if (selectedItems.length === 0) {
      this.notifier.notify('error', 'Please select at least one email to delete.');
      return;
    }
  
    this.emailBlackListService.deleteEmail(selectedItems).subscribe({
      next: () => {
        this.notifier.notify('success', 'Selected emails deleted successfully');
        this.emailBlackList = this.emailBlackList.filter(item => !selectedItems.includes(item));
        this.filteredEmails = [...this.emailBlackList]; 
      },
      error: (error) => {
        console.error("Error deleting emails:", error);
        this.notifier.notify('error', 'Error deleting selected emails.');
      }
    });
  }

  onSearch(searchValue: string): void {
    this.filteredEmails = this.emailBlackList.filter(item =>
      item.email.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  openEntry(value: any): void {
    this.router.navigate(["../email-black-list", value.id], {
      relativeTo: this.route,
    });
  }

}
