<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Manage Loan Status</h1>
      <app-navpath></app-navpath>
    </div>
    <div class="mb-2 mt-4 flex w-fit gap-1">
        <button class="shorter-btn misc-btn"  (click)="updateEntry()">
            <fa-icon [icon]="faSave" class="mr-1"></fa-icon>Update
        </button>
    </div>
    <div class="content-container">
      <app-data-form
        [dataFields]="formFields"
        (clickSearch)="fetchLoanStatus($event)"
        (fieldChange)="onFieldChange($event)"
        (updateFields_child)="onFieldChange($event)"
      ></app-data-form>
    </div>
</section>
  