import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilService } from '../http-util.service';

@Injectable({
  providedIn: 'root',
})
export class LoanStatusService {
  constructor(private http: HttpUtilService) {}

  getLoanStatus(referenceId: string): Observable<any> {

    const encodedRef = encodeURIComponent(referenceId);
    const url = `/LoanStatusService.asmx/GetLoanStatus?isSearchByRefNo=true&referenceId=${encodedRef}`;
  
    return this.http.get(url);
  }

  changeLoanAmount(payload: { LoanAmount: number; BuyersOrderNumber: string; LoanPeriod: number; LoanRate: number }): Observable<any> {
    const url = `/LoanStatusService.asmx/ChangeLoanAmount`;
    return this.http.post(url, payload);
  }
  
  updateLoanStatus(payload: any): Observable<any> {
    const url = `/LoanStatusService.asmx/Update`;
    return this.http.post(url, payload);
  }
  
}
