<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Outstanding COE Bidding</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <app-data-form [dataFields]="FormFields" />
    <div class="mt-2 flex gap-x-1">
      <button class="shorter-btn show-btn" (click)="preview()">
        <fa-icon [icon]="faEye" class="mr-1" />Preview
      </button>
      <button class="shorter-btn print-btn" (click)="print()">
        <fa-icon [icon]="faPrint" class="mr-1" />Print
      </button>
    </div>
  </div>
  <div class="content-container" *ngIf="TableEntries.length > 0">
    <table class="w-full max-w-full overflow-x-auto text-sm">
      <thead>
        <tr class="table-header">
          <th *ngFor="let header of TableHeaders">
            {{ header.title }}
          </th>
        </tr>
      </thead>
      <tbody *ngFor="let group of TableEntries">
        <tr
          class="table-row"
          *ngFor="let entry of group.OutstandingCOEBidding"
        >
          <td class="table-entry" *ngFor="let header of TableHeaders">
            <ng-container *ngIf="header.type === 'link'">
              <p class="link" (click)="openEntry(entry[header.id])">
                {{ entry[header.id] }}
              </p>
            </ng-container>
            <ng-container *ngIf="header.type === 'data'">
              {{ entry[header.id] || "" }}
            </ng-container>
          </td>
        </tr>
        <tr class="bg-blue-100 font-bold">
          <td colspan="11">
            {{ group.ModelGroup == " " ? "Grand" : group.ModelGroup }}
            Totals: ({{ group.OutstandingCOEBidding.length }}) records
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
