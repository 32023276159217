<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Vehicle Stock Report</h1>
      <app-navpath />
    </div>
    <div class="content-container">
      <h2 class="sub-heading">Vehicle Stock Report</h2>
      <app-data-form [dataFields]="formFields"></app-data-form>
      <div class="mb-2 mt-4 flex w-fit gap-1">
        <button class="shorter-btn misc-btn" (click)="exportReport()">
          <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Export
        </button>
      </div>
    </div>
</section>
  