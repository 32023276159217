import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class AllInvoicesService {
  private readonly apiUrl: string = "SalesReportService.asmx/AllInvoicesReport";

  constructor(private http: HttpUtilService) {}

  /**
   * Retrieves all invoices based on the specified date range.
   * @param fromDate The start date of the report in the format "dd/MM/yyyy".
   * @param toDate The end date of the report in the format "dd/MM/yyyy".
   * @returns An Observable containing the response from the API.
   */
  getAllInvoices(fromDate: string, toDate: string): Observable<any> {
    const payload = {
      model: {
        FromDate: fromDate,
        ToDate: toDate,
      },
    };

    return this.http.post(this.apiUrl, payload);
  }
}
