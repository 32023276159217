import { Component, OnInit } from "@angular/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { VehicleStockReportService } from "src/app/services/sales-reports/vehicle-stock-report.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-vehicle-stock-report",
  templateUrl: "./vehicle-stock-report.component.html",
  styleUrls: ["./vehicle-stock-report.component.css"],
})
export class VehicleStockReportComponent implements OnInit {
  faPrint = faPrint;

  formFields: FormField[] = [
    {
      type: "radio",
      id: "VehicleType",
      label: "Vehicle Type",
      options: [
        { display: "Hyundai Cars", value: "NORMAL" },
        { display: "Taxis", value: "TAXI" },
      ],
      value: "NORMAL",
    },
    {
      type: "radio",
      id: "StockStatus",
      label: "Stock Status",
      options: [
        { display: "Actual Stock", value: "ActualStock" },
        { display: "Stock on Arrival", value: "StockOnArrival" },
      ],
      value: "ActualStock",
    },
    {
      type: "date",
      id: "ToDate",
      label: "Closing Stock as on (dd/mm/yyyy)",
      value: "",
    },
    {
      type: "select",
      id: "Format",
      label: "Select format",
      value: "PDF",
      options: [
        { value: "PDF", display: "PDF" },
        { value: "EXCEL", display: "EXCEL" },
      ],
    },
  ];

  constructor(
    private vehicleStockReportService: VehicleStockReportService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  exportReport(): void {
    const vehicleType = this.getFieldValue("VehicleType");
    const toDate = this.getFieldValue("ToDate");
    const stockStatus = this.getFieldValue("StockStatus");

    if (!toDate) {
      this.notifier.notify("error", "Please select a closing stock date.");
      return;
    }

    const formattedToDate = this.formatDate(toDate);

    const payload = {
      model: {
        ToDate: formattedToDate,
        CarType: vehicleType,
        StockType: stockStatus,
      },
    };

    this.vehicleStockReportService.getVehicleStockReport(formattedToDate, vehicleType, stockStatus).subscribe({
      next: (response) => {
        this.handleReportResponse(response);
      },
      error: (error) => {
        console.error("Error generating Vehicle Stock Report:", error);
        this.notifier.notify("error", "Error generating Vehicle Stock Report.");
      },
    });
  }

  private handleReportResponse(response: any): void {
    const format = this.getFieldValue("Format");
    switch (format) {
      case "PDF":
        if (response.body?.Name) {
          window.open(response.body.Name, "_blank");
        } else {
          this.notifier.notify("error", "PDF file is not available.");
        }
        break;
      case "EXCEL":
        if (response.body?.Value) {
          window.open(response.body.Value, "_blank");
        } else {
          this.notifier.notify("error", "Excel file is not available.");
        }
        break;
      default:
        this.notifier.notify("error", "Invalid format selected.");
    }
  }

  private getFieldValue(id: string): any {
    const field = this.formFields.find((f) => f.id === id);
    return field?.value || "";
  }

  private formatDate(date: string): string {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }
}
